import React, { useEffect, useState, Fragment, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../../../components/tables/table";
import { product_columns } from "../../../../components/tables/tableheader";
import { productSchema, planMasterSchema } from "../../../../schemas";
import { Dialog, Transition } from "@headlessui/react";
import Pagination from "../../../../components/Pagination";
import { FallingLinesLoader } from "../../../../components/spinners/Spinner";
import Breadcrumb from "../../../../components/Breadcrumb";
import ConfirmationModal from "../../../../components/ConfirmationModal";
import DeleteConfirmationModal from "../../../../components/DeleteConfirmationModal";
import { ArrowDownCircleIcon, ArrowUpCircleIcon, PlusCircleIcon, XMarkIcon } from "@heroicons/react/24/outline";
import MasterServices from "../../../../ApiServices/MasterServices";
import prodServices from "../../../../ApiServices/prodServices";
import { toast } from "react-toast";
import makeAnimated from 'react-select/animated';
import moment from 'moment';
import { utils, writeFile, read } from 'xlsx';
import { getAllowedActions, checkPermissionsAndRedirect, capitalizeFirstLetter } from "../../../../helper/commonHelper";
import { handleDynamicFileImport } from '../../../../helper/dynamicImport';
import FileUploadWithPreview from "../../../../components/FileUploadWithPreview";
import planServices from "../../../../ApiServices/planServices";
import prodFileServices from "../../../../ApiServices/prodFileServices";
import Select from "react-select";
import { FaTrashAlt, FaSpinner, FaLink, FaDownload } from "react-icons/fa";


function ProductList() {
  let ProductBASEURLFILE = process.env.REACT_APP_LOCAL_File_URL + 'products/';
  const pages = [{ title: "Product List", href: "/product", module_id: 2 }];
  const animatedComponents = makeAnimated();
  const [modalOpenFlage, setmodalOpenFlage] = useState(false);
  const [prod_id, setProductid] = useState("");
  const [prodList, setProductList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedPlan, setSelectedPlan] = useState([]);
  const [selectedProdType, setSelectedProdType] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState({});

  const [prodTypeList, setProductTypeList] = useState([
    {
      label: "MT4",
      value: "MT4",
    },
    {
      label: "MT5",
      value: "MT5",
    },
    {
      label: "GLOBAL",
      value: "GLOBAL",
    },
  ]);

  const [plansTypeList, setPlansTypeList] = useState([
    {
      label: "MONTHLY",
      value: "MONTHLY",
    },
    {
      label: "QUARTERLY",
      value: "QUARTERLY",
    },
    {
      label: "SEMI_ANNUALY",
      value: "SEMI_ANNUALY",
    },
    {
      label: "YEARLY",
      value: "YEARLY",
    },
    {
      label: "FREE",
      value: "FREE",
    },
  ]);
  const handlePlanType = (e) => {
    setSelectedPlan(e);
  };

  const handleProdType = (e) => {
    setSelectedProdType(e);
  };


  const [importLoader, setImportLoader] = useState(false);
  const fileInputRef = useRef(null);

  const [error, setError] = useState(null);

  // // Define your entity map for dynamic import (example)
  const entityProductMap = {
    ProductName: 'ProductName',
    ProductDesc: 'ProductDesc',
    ProductType: 'ProductType',
    ProductOnboardingCharge: 'ProductOnboardingCharge',
    ProductUrl: 'ProductUrl',
    ProductBonus: 'ProductBonus',
  };
  // console.log('errors=>',errors)
  // // Define the entity you are working with (in this case 'barcode')
  const entityProduct = "Product";

  const handleProductButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleProductImport = (event) => {
    const file = event.target.files[0];

    // File type validation
    if (file && !file.name.match(/\.(xlsx|xls)$/)) {
      setError("Please upload a valid Excel file.");
      return;
    }
    if (file) {
      setImportLoader(true);

      // Call the dynamic import utility function
      handleDynamicFileImport(file, entityProductMap)
        .then((importData) => {
          if (importData.length > 0) {
            console.log('importData=>', importData)
            // Call API to save the imported data
            importEntities(importData);
          } else {
            setImportLoader(false);
            toast.warning('No valid data found in the file');
          }
        })
        .catch((error) => {
          setImportLoader(false);
          toast.error(`Error processing file: ${error.message}`);
        });
    } else {
      toast.error('No file selected');
    }
  };

  const importEntities = (data) => {
    const payload = { excel_data: data };
    console.log('importEntities payload=>', payload, data)

    prodServices.importProduct(payload, entityProduct)
      .then((response) => {
        setImportLoader(false);
        if (response.status === 200) {
          toast.success(`${entityProduct} imported successfully`);
          getAllProducts(3);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        setImportLoader(false);
        toast.error(`Error while importing ${entityProduct}`);
      });
  };

  const handleFileUpload = (uploadedFiles) => {
    let payload = {
      pfvl_name: uploadedFiles?.fileName,
      pfvl_path: uploadedFiles?.filePath,
      pfvl_size: uploadedFiles?.fileSizeKB,
      pfvl_mime_type: uploadedFiles?.mimeType,
    }

    setUploadedFiles(payload);
    console.log("Files received from child:", uploadedFiles);
  };

  const planInitialValuse = {
    plan_id: "",
    plan_name: "",
    plan_desc: "",
    plan_expiry: "",
    plan_subs_price: "",
    plan_onboarding_charge: "",
    plan_period: "MONTHLY",
    fk_prod_id: ""
  }

  const [planModal, setPlanModal] = useState(false)
  const [singleProduct, setSingleProduct] = useState({})
  const [currentProductID, setCurrentProductID] = useState(0)
  const [formPlan, setFormPlan] = useState(planInitialValuse);
  const [modalOpen, setModalOpen] = useState(false);
  const [fileList, setFileList] = useState([]);

  const planModalOpen = (id, obj) => {

    setCurrentProductID(id);
    setPlanModal(true)
    setSingleProduct(obj)
  }

  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    prod_id: null,
  });

  const [removeConfirmationModal, setRemoveConfirmationModal] = useState({
    status: false,
    prod_id: null,
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [allowedActions, setAllowedActions] = useState([]);

  useEffect(() => {
    const permissions = JSON.parse(localStorage.getItem("user_permissions"));
    if (permissions && window.location.pathname) {
      const checkPermissionsAndRedirectFunc = checkPermissionsAndRedirect(window.location.pathname, permissions, navigate);
      const actions = getAllowedActions(permissions, window.location.pathname);
      setAllowedActions(actions);
    }
  }, []);

  useEffect(() => {
    document.title = "EdgeFin •  Products";
    getAllProducts(3);
  }, []);

  const getAllProducts = async (payload) => {
    setIsLoading(true);
    try {
      const res = await prodServices.getAllProduct(payload)
      if (res.status) {
        console.log("res.data.data=>", res.data.data?.products)
        setProductList(res?.data?.data?.products);
        // toast.success(res?.data?.message);
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e, "error in getAllModules")
      toast.error("Fatal Error")
      setIsLoading(false);
    }
  }

  const onStatusUpdate = (prod_id, prod_is_active) => {
    let payload = {
      table_name: "Product_master",
      table_field: "prod_id",
      table_field_val: prod_id,
      table_status_field: "prod_is_active",
      table_status_val: prod_is_active ? false : true,
      table_text: "Product",
      deleted_by_key: "prod_uu_by",
      delete_active_txt: prod_is_active ? " Disabled" : " Enabled",
    }
    MasterServices.removeMaster(payload).then((res) => {
      const { data, status, message } = res.data
      if (status) {
        getAllProducts(3);
        toast.success(message);
      } else {
        toast.error(message)
      }
    })
  }

  let initialValues = {
    prod_id: "",
    prod_type: "",
    prod_title: "",
    prod_desc: "",
    prod_file: "",
    prod_onboarding_charge: "",
    prod_bonus: "",

  };

  const [formProduct, setFormProduct] = useState(initialValues);

  const handleDrawer = (type, id, obj) => {
    if ((type === "edit", id)) {
      setProductid(id);
      const initialValues = {
        prod_id: obj.prod_id,
        prod_type: obj.prod_type,
        prod_title: obj.prod_title,
        prod_desc: obj.prod_desc,
        prod_file: obj.prod_file,
        prod_onboarding_charge: obj.prod_onboarding_charge,
        prod_bonus: obj.prod_bonus,

      };
      const filteredType = prodTypeList.find((type) => type.value === obj.prod_type);
      setSelectedProdType(filteredType);
      // setUploadedFiles(obj?.product_file_version_log);
      setFormProduct(initialValues);
    } else {
      setProductid("");
      setFormProduct(initialValues);
    }
    if (modalOpenFlage === false) {
      setmodalOpenFlage(true);
    }
  };

  const onDeleteOpen = (prod_id, prod_is_active) => {
    setConfirmationModal({ prod_id, prod_is_active, status: true });
  };

  const onDeleteOpenSection = (prod_id, prod_is_deleted) => {
    setRemoveConfirmationModal({ prod_id, prod_is_deleted, status: true });
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: formProduct,
      validationSchema: productSchema,
      onSubmit: async (values, action) => {

        let body = {
          prod_id: prod_id,
          prod_type: values.prod_type,
          prod_title: values.prod_title,
          prod_desc: values.prod_desc,
          prod_file: values.prod_file,
          prod_onboarding_charge: values.prod_onboarding_charge,
          prod_bonus: values.prod_bonus,
          FileLogs: uploadedFiles,
        };

        if (body.prod_title != undefined || body.prod_title != null || body.prod_title != "") {
          setProductList([]);
          setIsLoading(true);
          setTimeout(() => {
            setDisableSubmitButton(false)
          }, 1000)
          setDisableSubmitButton(true)
          let { data, status, message } = await prodServices.SaveProduct(body)
          if (status === 200) {
            if (data.status) {
              action.resetForm();
              toast.success(data.message);
              getAllProducts(3);
              setIsLoading(false)
              setmodalOpenFlage(false);
              setDisableSubmitButton(false)
            } else {
              toast.success(data.message);
              setErrorMessage(data.message);
              setTimeout(() => {
                setErrorMessage('')
              }, 1000)
              setIsLoading(false);
              setDisableSubmitButton(false);
            }
          } else {
            setDisableSubmitButton(false);
          }
        } else {
          setDisableSubmitButton(false)

          action.resetForm();
          if (modalOpenFlage === true) {
            getAllProducts(3);
            setmodalOpenFlage(false);
          }
          setDisableSubmitButton(false)
        }
      },
    });

  const onDeleteProduct = async (prod_id) => {
    let { data, status } = await prodServices.deleteProduct({ prod_id })
    if (status === 200) {
      if (data.status) {

        toast.success(data.message);
        getAllProducts(3);
      }
    } else {

      toast.error(data.message);
    }
    setRemoveConfirmationModal({ prod_id: null, status: false });
  };

  const handleExport = (excel_type) => {
    if (!prodList || prodList.length === 0) {
      toast.error("Product list is empty!");
      return;
    }
    if (prodList?.length > 0) {
      let newSheetdata = [];
      prodList.map((obj, index) => {
        const newData = {
          SN: parseInt(index) + 1,
          prod_id: obj?.prod_id || '-',
          prod_title: capitalizeFirstLetter(obj?.prod_title) || '-',
          prod_type: capitalizeFirstLetter(obj?.prod_type) || '-',
          prod_file: (obj?.prod_file) || '-',
          prod_desc: capitalizeFirstLetter(obj?.prod_desc) || '-',
          prod_onboarding_charge: obj?.prod_onboarding_charge || '-',
          // prod_bonus: obj?.prod_bonus || '-',
          prod_created_at: moment(obj?.prod_created_at).format("DD-MM-YYYY") || '-',
          Status: obj?.prod_is_active == true ? 'Active' : 'In-Active' || '-',
          user_username: obj?.mprod_cu_relation?.user_username || '-',
        }
        newSheetdata.push(newData);
      });
      let currentDate = new Date();
      let fileName = 'Product_Excel_Report_' + moment(currentDate).format("DD-MM-YYYY") + '.' + excel_type;
      // 'Bonus',
      const headings = [['SN', 'Product ID', 'Product Name', 'Product Type', 'Product File', 'Product Description Charges', 'Product On Boarding',  'Created Date', 'Status', 'Created By']];
      const wb = utils.book_new();
      const ws = utils.json_to_sheet([]);
      utils.sheet_add_aoa(ws, headings);
      utils.sheet_add_json(ws, newSheetdata, { origin: 'A2', skipHeader: true });
      utils.book_append_sheet(wb, ws, 'Report');
      writeFile(wb, fileName);
    } else {
      toast.error("Data Not Found!");
    }
  }

  const getProductFile = (id) => {
    setProductid(id);
    getAllProductFile(id);
    setModalOpen(true);
  };

  const closeModal = () => {
    setProductid("");
    setModalOpen(false);
  };
  const getAllProductFile = async (payload) => {
    setIsLoading(true);
    try {
      const res = await prodFileServices.getAllFile({ fk_prod_id: payload })
      if (res.status) {
        setFileList(res?.data?.data?.product_files);
        toast.success(res?.data?.message);
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e, "error in getAllModules")
      toast.error("Fatal Error")
      setIsLoading(false);
    }
  }
  const handleDownloadFile = (filePath, isDeprecated) => {
    // if (isDeprecated) {
    //   toast.warn("This file is deprecated and cannot be downloaded.");
    // } else {
    //   toast.info(`Downloading file from ${filePath}`);
    // }
  };

  return isLoading ? (
    <FallingLinesLoader />
  ) : (
    <div className="px-4 sm:px-4 lg:px-4">
      {/* Modal for Add/Update Account */}
      {modalOpen && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-gray-900 p-8 rounded-lg shadow-lg w-[800px] max-w-full">
            {/* <h3 className="text-2xl font-semibold text-white mb-6">
              Modal Title
            </h3> */}
            <div>
              {/* Add the Files Section */}
              {fileList?.length > 0 && (
                <div className="mt-6">
                  <h4 className="text-xl font-semibold text-white">Files</h4>
                  <ul className="mt-3 space-y-4">
                    {fileList?.map((file,index) => (
                      <li
                        key={index}
                        className="flex justify-between items-center space-x-4 bg-gray-700 p-3 rounded-md transition-all hover:bg-gray-600"
                      >
                        <span className="text-sm text-gray-200">{file.pfvl_name}</span>
                        <button
                          onClick={(e) => {
                            e.preventDefault(); 
                            handleDownloadFile(file.pfvl_name, file.pfvl_is_depricated); // Call the handle function
                            // if (!file.pfvl_is_depricated) {
                              window.location.href = `${ProductBASEURLFILE}${file.pfvl_name}`;
                            // }
                          }}
                          className={`text-xs text-blue-400 hover:text-blue-300}`}
                          // disabled={file.pfvl_is_depricated}
                        >
                          <FaDownload className="inline mr-2" />
                          {" Download"}
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
            {/* Modal Actions */}
            <div className="flex justify-end mt-8">
              <button
                type="button"
                onClick={closeModal}
                className="bg-red-600 py-2 px-6 rounded text-white text-sm font-semibold hover:bg-red-500"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}


      <ConfirmationModal
        title={
          "Are you sure you want to " +
          (confirmationModal.prod_is_active ? "Disable " : "Enable") +
          " this Product?"
        }
        confirmationButtonText="Yes"
        open={confirmationModal.status}
        onDelete={() =>
          onStatusUpdate(
            confirmationModal.prod_id,
            confirmationModal.prod_is_active
          )
        }
        setOpen={setConfirmationModal}
      />

      <DeleteConfirmationModal
        title={
          "Are you sure you want to " +
          (removeConfirmationModal.prod_is_deleted
            ? " Undo"
            : "Delete") +
          " this Product?"
        }
        confirmationButtonText="Yes"
        open={removeConfirmationModal.status}
        onDelete={() => {
          console.log("confirmationModal = ", removeConfirmationModal);
          onDeleteProduct(removeConfirmationModal.prod_id);
        }}
        setOpen={setRemoveConfirmationModal}
      />

      <Breadcrumb pages={pages} />
      <div className="">
        <h1 className="text-xl font-semibold text-white">Product List</h1>
      </div>
      <div className="mt-4 flex">
        {allowedActions.filter((data) => data.permission_id == 28)?.length >
          0 ? (
          <Link
            onClick={() => handleDrawer("add", "", {})}
            type="button"
            className="inline-flex items-center justify-center rounded-md bbt bg-[#27ff10] px-4 py-2 text-sm font-medium text-black shadow-sm hffff  ml-auto"
          >
            <PlusCircleIcon className="-ml-1.5 h-5 w-5 mr-1" aria-hidden="true" />
            Add Product
          </Link>
        ) : null}
        {allowedActions.filter((data) => data.permission_id == 47)?.length > 0 && prodList?.length > 0 ? (
          <Link
            to={"#"}
            onClick={() => handleExport('xlsx')}
            type="button"
            className="inline-flex items-center justify-center rounded-md bbt bg-[#27ff10] px-4 py-2 text-sm font-medium text-black shadow-sm hffff  ml-2"
          >
            <ArrowDownCircleIcon className="-ml-1.5 h-5 w-5 mr-1" aria-hidden="true" />
            Download Excel
          </Link>
        ) : null}
        {allowedActions.filter((data) => data.permission_id == 48)?.length > 0 ? (<> <button
          className="inline-flex items-center justify-center rounded-md bbt bg-[#27ff10] px-4 py-2 text-sm font-medium text-black shadow-sm hffff  ml-2"
          disabled={importLoader}
          onClick={handleProductButtonClick}
          aria-live="polite" // Optional for screen readers
        >
          {importLoader ? (
            <>
              <FaSpinner color="gold" className="spinner animate-spin" />
              <span className="ml-2">Uploading Please Wait ...</span>
            </>
          ) : (
            "Upload Product"
          )}
        </button>
          <input
            accept=".xlsx, .xls"
            onChange={handleProductImport}
            type="file"
            name="user_excel"
            autoComplete="off"
            ref={fileInputRef}
            className="block w-full bg-white max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
            style={{ display: 'none' }} // Hide the file input
          /></>) : null}
      </div>

      {isLoading ? (
        <FallingLinesLoader />
      ) : (
        <Table
          columns={product_columns({
            planModalOpen,
            onDeleteOpen,
            onDeleteOpenSection,
            handleDrawer,
            getProductFile,
            allowedActions,
          })}
          data={prodList}
          is_toggle={false}
        />
      )}

      <div>
        <Transition.Root show={modalOpenFlage} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={setmodalOpenFlage}
          >
            <div className="fixed inset-0" />
            <div className="fixed inset-0 overflow-hidden">
              <div className="absolute inset-0 overflow-hidden">
                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                  <Transition.Child
                    as={Fragment}
                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                  >
                    <Dialog.Panel className="pointer-events-auto w-screen max-w-xs">
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          handleSubmit(e);
                        }}
                        className="flex h-full flex-col divide-y divide-gray-700 bg-gray-800 shadow-lg"
                      >
                        <div className="h-0 flex-1 overflow-y-auto">
                          <div className="bg-gradient-to-b from-[#2e2e34] from-10% via-gray-900 via-9% to-gray-900 to-80% py-6 px-4 sm:px-6">
                            <div className="flex items-center justify-between">
                              <Dialog.Title className="text-lg font-medium text-white">
                                {prod_id ? "Update" : "Add"} Product
                              </Dialog.Title>
                              <div className="ml-3 flex h-7 items-center">
                                <button
                                  type="button"
                                  className="rounded-md text-cyan-200 hover:text-white"
                                  onClick={() => setmodalOpenFlage(false)}
                                >
                                  <span className="sr-only">Close panel</span>
                                  <XMarkIcon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                  />
                                </button>
                              </div>
                            </div>
                          </div>

                          <div className="flex flex-1 flex-col justify-between">
                            <div className="p-4 sm:p-6">
                              <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="prod_title"
                                    className="block text-sm font-medium text-gray-300 mb-1"
                                  >
                                    Product Name

                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <input
                                    value={values.prod_title}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="text"
                                    placeholder="Product Name"
                                    name="prod_title"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-600 bg-gray-700 text-white shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                  />
                                  {errors.prod_title && touched.prod_title ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.prod_title.replace("prod_title", "Product Name")}
                                    </p>
                                  ) : null}
                                </div>
                              </div>
                              <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="prod_type"
                                    className="block text-sm font-medium text-gray-300 mb-1"
                                  >
                                    Type
                                    <span className="text-red-600">*</span>
                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <Select
                                    name="prod_type"
                                    placeholder={
                                      <span>
                                        Select Type
                                        <span className="text-red-600">
                                          *
                                        </span>
                                      </span>
                                    }
                                    onChange={(e) => {
                                      values.prod_type = e.value;
                                      handleProdType(e);
                                    }}
                                    value={selectedProdType}
                                    components={animatedComponents}
                                    options={prodTypeList}
                                    classNamePrefix="select"
                                    className="basic-multi-select block w-full rounded-md border-[1px]  shadow-sm  focus:border-red-500 sm:text-sm"
                                  />
                                  {/* <input
                                    value={values.prod_type}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="text"
                                    placeholder="Product Name"
                                    name="prod_type"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-600 bg-gray-700 text-white shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                  /> */}
                                  {errors.prod_type && touched.prod_type ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.prod_type.replace("prod_type", "Product Type")}
                                    </p>
                                  ) : null}
                                </div>
                              </div>
                              <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="prod_onboarding_charge"
                                    className="block text-sm font-medium text-gray-300 mb-1"
                                  >
                                    Onboarding Charges
                                    <span className="text-red-600">*</span>
                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <input
                                    value={values.prod_onboarding_charge}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="text"
                                    placeholder="Product Onboarding Charges"
                                    name="prod_onboarding_charge"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-600 bg-gray-700 text-white shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                  />
                                  {errors.prod_onboarding_charge && touched.prod_onboarding_charge ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.prod_onboarding_charge.replace("prod_onboarding_charge", "Product Onboarding Charges")}
                                    </p>
                                  ) : null}
                                </div>
                              </div>
                               {/*<div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="prod_bonus"
                                    className="block text-sm font-medium text-gray-300 mb-1"
                                  >
                                    Bonus
                                    <span className="text-red-600">*</span>
                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <input
                                    value={values.prod_bonus}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="text"
                                    placeholder="Product Bonus"
                                    name="prod_bonus"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-600 bg-gray-700 text-white shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                  />
                                  {errors.prod_bonus && touched.prod_bonus ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.prod_bonus.replace("prod_bonus", "Product Bonus")}
                                    </p>
                                  ) : null}
                                </div>
                              </div> */}
                              <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">

                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <FileUploadWithPreview
                                    onFileUpload={handleFileUpload}
                                    bannerName="Product Upload"
                                    maxFileSize={500}
                                    FolderName={'products'}
                                    multiple={false}
                                    doc_id={prod_id}
                                  />
                                  {errors.prod_type && touched.prod_type ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.prod_type.replace("prod_type", "Product Type")}
                                    </p>
                                  ) : null}
                                </div>
                              </div>
                              <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="prod_desc"
                                    className="block text-sm font-medium text-gray-300 mb-1"
                                  >
                                    Product Desc.
                                    <span className="text-red-600">*</span>
                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <textarea
                                    rows="4"
                                    value={values.prod_desc}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="text"
                                    placeholder="Product Desc."
                                    name="prod_desc"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-600 bg-gray-700 text-white shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                  />
                                  {errors.prod_desc && touched.prod_desc ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.prod_desc.replace("prod_desc", "Product Desc.")}
                                    </p>
                                  ) : null}
                                </div>
                              </div>

                              <div className="mt-1 pt-2 pb-2 sm:col-span-2 sm:mt-0">
                                <div className="basis-1/2 text-red-500 text-sm">
                                  {errorMessage !== "" && "Error: " + errorMessage}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-shrink-0 justify-end px-4 py-4">
                          <button
                            type="button"
                            className="rounded-md border border-gray-600 bg-gray-700 py-2 px-4 text-sm font-medium text-gray-300 shadow-sm hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                            onClick={() => {
                              setmodalOpenFlage(false);
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            disabled={disableSubmitButton}
                            type="submit"
                            className={`ml-4 inline-flex items-center justify-center rounded-md bbt bg-[#27ff10] px-4 py-2 text-sm font-medium text-black shadow-sm hffff  ml-2 ${disableSubmitButton
                              ? "bg-gray-700 hover:bg-gray-700"
                              : ""
                              }`}
                          >
                            {prod_id ? (
                              disableSubmitButton ? (
                                <FaSpinner color="gold" />
                              ) : (
                                "Update"
                              )
                            ) : disableSubmitButton ? (
                              <FaSpinner color="gold" />
                            ) : (
                              "Add"
                            )}
                          </button>
                        </div>
                      </form>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        <Transition.Root show={planModal} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={setPlanModal}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:w-full sm:max-w-md">
                    <div className="flex items-center text-lg font-extrabold px-4 text-white py-2 bg-gradient-to-b from-[#27ff10] to-[#27ff10] italic justify-between">
                      <h1 className="text-lg font-bold text-black">
                        Add Plan for {singleProduct ? singleProduct.prod_title : null}
                      </h1>
                      <XMarkIcon
                        className="text-white h-8 cursor-pointer w-8 ml-auto"
                        aria-hidden="true"
                        onClick={() => {
                          setPlanModal(false);
                        }}
                      />
                    </div>
                    <div className="p-5">
                      <Formik
                        initialValues={formPlan}
                        validationSchema={planMasterSchema}
                        onSubmit={(values, { setSubmitting }) => {
                          console.log('currentProductID=>', currentProductID)
                          const payload = {
                            plan_id: 0, plan_name: values.plan_name, plan_desc: values.plan_desc, plan_expiry: values.plan_expiry, plan_subs_price: values.plan_subs_price, plan_onboarding_charge: values.plan_onboarding_charge, plan_period: values.plan_period, fk_prod_id: currentProductID
                          }
                          if (payload.plan_name != undefined || payload.plan_name != null || payload.plan_name != "") {
                            setTimeout(() => {
                              setDisableSubmitButton(false)
                            }, 1000)
                            setDisableSubmitButton(true)
                            planServices.SavePlan(payload).then((res) => {
                              const { data, status, message } = res.data;
                              if (status) {

                                setCurrentProductID(0);
                                setPlanModal(false)
                                const planInitialValuse = {
                                  plan_id: "",
                                  plan_name: "",
                                  plan_desc: "",
                                  plan_expiry: "",
                                  plan_subs_price: "",
                                  plan_onboarding_charge: "",
                                  plan_period: "MONTHLY",
                                  fk_prod_id: ""
                                }
                                setFormPlan(planInitialValuse);
                                toast.success(message);
                                setDisableSubmitButton(false)
                                //action.resetForm();
                              } else {
                                toast.error(message);
                                setDisableSubmitButton(false)
                              }
                            }).catch((error) => {
                              console.log(error)
                              setDisableSubmitButton(false)
                            });
                          }

                        }}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          /* and other goodies */
                        }) => (
                          <form
                            onSubmit={(e) => {
                              e.preventDefault();
                              handleSubmit(e);
                            }}
                          // className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl"
                          >

                            <div className="my-2 sm:col-span-2 sm:mt-0">
                              <div>
                                <label
                                  htmlFor="plan_name"
                                  className="block text-sm font-medium text-gray-900 mb-1"
                                >
                                  Name<span className="text-red-600">*</span>
                                </label>
                              </div>
                              <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                <input
                                  value={values.plan_name}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  type="text"
                                  placeholder="Name"
                                  name="plan_name"
                                  autoComplete="off"
                                  className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-[#BE3144] sm:text-sm"
                                />
                                {errors.plan_name &&
                                  touched.plan_name ? (
                                  <p className="text-red-600 text-sm">
                                    {errors.plan_name}
                                  </p>
                                ) : null}
                              </div>
                            </div>

                            <div className="my-2 sm:col-span-2 sm:mt-0">
                              <div>
                                <label
                                  htmlFor="plan_period"
                                  className="block text-sm font-medium text-gray-900 mb-1"
                                >
                                  Type<span className="text-red-600">*</span>
                                </label>
                              </div>
                              <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">

                                <Select
                                  name="plan_period"
                                  placeholder={
                                    <span>
                                      Select Type
                                      <span className="text-red-600">
                                        *
                                      </span>
                                    </span>
                                  }
                                  onChange={(e) => {
                                    values.plan_period = e.value;
                                    handlePlanType(e);
                                  }}
                                  value={selectedPlan}
                                  components={animatedComponents}
                                  options={plansTypeList}
                                  classNamePrefix="select"
                                  className="basic-multi-select block w-full rounded-md border-[1px]  shadow-sm  focus:border-red-500 sm:text-sm"
                                />

                                {errors.plan_period && touched.plan_period ? (
                                  <p className="text-red-600 text-sm">{errors.plan_period}</p>
                                ) : null}
                              </div>
                            </div>



                            {/* <div className="my-2 sm:col-span-2 sm:mt-0">
                              <div>
                                <label
                                  htmlFor="plan_expiry"
                                  className="block text-sm font-medium text-gray-900 mb-1"
                                >
                                  Expiry Date<span className="text-red-600">*</span>
                                </label>
                              </div>
                              <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                <input
                                  value={values.plan_expiry}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  type="date"
                                  min={new Date().toISOString().split('T')[0]}
                                  placeholder="Date"
                                  name="plan_expiry"
                                  autoComplete="off"
                                  className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-[#BE3144] sm:text-sm"
                                />
                                {errors.plan_expiry &&
                                  touched.plan_expiry ? (
                                  <p className="text-red-600 text-sm">
                                    {errors.plan_expiry}
                                  </p>
                                ) : null}
                              </div>
                            </div> */}

                            <div className="my-2 sm:col-span-2 sm:mt-0">
                              <div>
                                <label
                                  htmlFor="plan_subs_price"
                                  className="block text-sm font-medium text-gray-900 mb-1"
                                >
                                  Subscription Price<span className="text-red-600">*</span>
                                </label>
                              </div>
                              <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                <input
                                  value={values.plan_subs_price}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  type="number"
                                  placeholder="Price"
                                  name="plan_subs_price"
                                  autoComplete="off"
                                  className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-[#BE3144] sm:text-sm"
                                />
                                {errors.plan_subs_price &&
                                  touched.plan_subs_price ? (
                                  <p className="text-red-600 text-sm">
                                    {errors.plan_subs_price}
                                  </p>
                                ) : null}
                              </div>
                            </div>
                            <div className="my-2 sm:col-span-2 sm:mt-0">
                              <div>
                                <label
                                  htmlFor="plan_desc"
                                  className="block text-sm font-medium text-gray-900 mb-1"
                                >
                                  Desc
                                </label>
                              </div>
                              <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                <textarea
                                  value={values.plan_desc}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  placeholder="Description"
                                  name="plan_desc"
                                  autoComplete="off"
                                  className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-[#BE3144] sm:text-sm"
                                  rows="2"
                                />
                                {errors.plan_desc &&
                                  touched.plan_desc ? (
                                  <p className="text-red-600 text-sm">
                                    {errors.plan_desc}
                                  </p>
                                ) : null}
                              </div>
                            </div>

                            <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                              <button
                                type="submit"

                                className="inline-flex w-full justify-center rounded-md bg-gradient-to-b from-[#27ff10] to-[#27ff10] px-3 py-2 text-sm font-semibold text-black shadow-sm hover:bg-gradient-to-b focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#27ff10] sm:col-start-2"
                                // onClick={() => planModalOpen(false)}
                                disabled={disableSubmitButton}
                              >

                                {(disableSubmitButton ? <FaSpinner color="gold" /> : "Add")}

                              </button>
                              <button
                                type="button"
                                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                                onClick={() => setPlanModal(false)}
                              >
                                Cancel
                              </button>
                            </div>
                          </form>
                        )}
                      </Formik>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

      </div>
    </div>
  );
}
export default ProductList;