import React, { useEffect, useState, Fragment, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useFormik, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../../components/tables/table";
import { chapter_columns } from "../../../components/tables/tableheader";
import { chapterMasterSchema } from "../../../schemas";
import { Dialog, Transition } from "@headlessui/react";
import Pagination from "../../../components/Pagination";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Breadcrumb from "../../../components/Breadcrumb";
import ConfirmationModal from "../../../components/ConfirmationModal";
import DeleteConfirmationModal from "../../../components/DeleteConfirmationModal";
import { ArrowDownCircleIcon, ArrowUpCircleIcon, PlusCircleIcon, XMarkIcon } from "@heroicons/react/24/outline";
import MasterServices from "../../../ApiServices/MasterServices";

import { toast } from "react-toast";
import { FaSpinner } from "react-icons/fa";
import makeAnimated from 'react-select/animated';
import moment from 'moment';
import { utils, writeFile, read } from 'xlsx';
import { getAllowedActions, checkPermissionsAndRedirect, capitalizeFirstLetter } from "../../../helper/commonHelper";
import { handleDynamicFileImport } from '../../../helper/dynamicImport';
import FileUploadWithPreview from "../../../components/FileUploadWithPreview";
import chapterServices from "../../../ApiServices/chapterServices";
import Select from "react-select";
import courseServices from "../../../ApiServices/courseServices";


function CourseChapterList() {
  const pages = [{ title: "Chapter List", href: "/chapter", module_id: 2 }];
  const animatedComponents = makeAnimated();
  const [modalOpenFlage, setmodalOpenFlage] = useState(false);
  const [chapter_id, setChapterId] = useState("");
  const [courseChapterList, setCourseChapterList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedChapter, setSelectedChapter] = useState([]);
  const [courseList, setCourseList] = useState([]);
  const [currentCourseID, setCurrentCourseID] = useState('')
  const [uploadedFiles, setUploadedFiles] = useState(null);

  const { course_id: course_id } = useParams();


  const [chaptersTypeList, setChaptersTypeList] = useState([
    {
      label: "TEXT",
      value: "TEXT",
    },
    {
      label: "FILE",
      value: "FILE",
    },
    {
      label: "VIDEO",
      value: "VIDEO",
    },
    {
      label: "AUDIO",
      value: "AUDIO",
    },
  ]);
  const handleChapterType = (e) => {
    setSelectedChapter(e);
  };

  const handleFileUpload = (uploadedFiles) => {
    setFieldValue("chapter_path", uploadedFiles?.fileName);
    setUploadedFiles(uploadedFiles?.fileName);
    console.log("Files received from child:", uploadedFiles);
  };

  const [importLoader, setImportLoader] = useState(false);
  const fileInputRef = useRef(null);

  const [error, setError] = useState(null);

  // // Define your entity map for dynamic import (example)
  const entityCourseChapterMap = {
    CourseName: 'CourseName',
    ChapterName: 'ChapterName',
    ChapterDesc: 'ChapterDesc',
    ChapterType: 'ChapterType',
    ChapterPath: 'ChapterPath',
    ChapterDuration: 'ChapterDuration',
  };
  const entityCourseChapter = "CourseChapter";

  const handleCourseButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleCourseImport = (event) => {
    const file = event.target.files[0];

    // File type validation
    if (file && !file.name.match(/\.(xlsx|xls)$/)) {
      setError("Please upload a valid Excel file.");
      return;
    }
    if (file) {
      setImportLoader(true);

      // Call the dynamic import utility function
      handleDynamicFileImport(file, entityCourseChapterMap)
        .then((importData) => {
          if (importData.length > 0) {
            console.log('importData=>', importData)
            // Call API to save the imported data
            importEntities(importData);
          } else {
            setImportLoader(false);
            toast.warning('No valid data found in the file');
          }
        })
        .catch((error) => {
          setImportLoader(false);
          toast.error(`Error processing file: ${error.message}`);
        });
    } else {
      toast.error('No file selected');
    }
  };

  const importEntities = (data) => {
    const payload = { excel_data: data };
    console.log('importEntities payload=>', payload, data)

    chapterServices.importChapter(payload, entityCourseChapter)
      .then((response) => {
        setImportLoader(false);
        if (response.status === 200) {
          toast.success(`${entityCourseChapter} imported successfully`);
          getAllChapters(3);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        setImportLoader(false);
        toast.error(`Error while importing ${entityCourseChapter}`);
      });
  };


  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    chapter_id: null,
  });

  const [removeConfirmationModal, setRemoveConfirmationModal] = useState({
    status: false,
    chapter_id: null,
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [allowedActions, setAllowedActions] = useState([]);

  // useEffect(() => {
  //   const permissions = JSON.parse(localStorage.getItem("user_permissions"));
  //   if (permissions && window.location.pathname) {
  //     const checkPermissionsAndRedirectFunc = checkPermissionsAndRedirect(window.location.pathname, permissions, navigate);
  //     const actions = getAllowedActions(permissions, window.location.pathname);
  //     setAllowedActions(actions);
  //   }
  // }, []);

  useEffect(() => {
    document.title = "EdgeFin •  Courses";
    setCurrentCourseID(course_id)
    getAllChapters(3);
    getAllCourses(1);
  }, []);

  const getAllCourses = async (payload) => {
    setIsLoading(true);
    try {
      const res = await courseServices.getAllCourse(payload)
      if (res.status) {
        console.log("res.data.data=>", res.data.data?.courses)
        setCourseList(res?.data?.data?.courses);
        // toast.success(res?.data?.message);
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e, "error in getAllModules")
      toast.error("Fatal Error")
      setIsLoading(false);
    }
  }

  const getAllChapters = async (payload) => {
    setIsLoading(true);
    try {
      const res = await chapterServices.getAllChapter({ search: payload, fk_course_id: course_id })
      if (res.status) {
        console.log("res.data.data=>", res.data.data?.chapters)
        setCourseChapterList(res?.data?.data?.chapters);
        // toast.success(res?.data?.message);
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e, "error in getAllModules")
      toast.error("Fatal Error")
      setIsLoading(false);
    }
  }

  const onStatusUpdate = (chapter_id, chapter_is_active) => {
    let payload = {
      table_name: "m_course",
      table_field: "chapter_id",
      table_field_val: chapter_id,
      table_status_field: "chapter_is_active",
      table_status_val: chapter_is_active ? false : true,
      table_text: "Course",
      deleted_by_key: "course_uu_by",
      delete_active_txt: chapter_is_active ? " Disabled" : " Enabled",
    }
    MasterServices.removeMaster(payload).then((res) => {
      const { data, status, message } = res.data
      if (status) {
        getAllChapters(3);
        toast.success(message);
      } else {
        toast.error(message)
      }
    })
  }

  let initialValues = {
    chapter_id: "",
    chapter_title: "",
    chapter_type: "",
    duration: "",
    chapter_time: "",
    chapter_desc: "",
    chapter_path: "",
    fk_course_id: currentCourseID || "",
  };

  const [formCourseChapter, setFormCourseChapter] = useState(initialValues);

  const handleDrawer = (type, id, obj) => {
    if ((type === "edit", id)) {
      setChapterId(id);
      const filteredChapterType = chaptersTypeList.find((type) => type.value === obj.chapter_type);
      const initialValues = {

        chapter_id: obj.chapter_id || "",
        chapter_title: obj.chapter_title || "",
        chapter_type: filteredChapterType?.value || "",
        duration: obj.duration || "",
        chapter_time: obj.chapter_time || "",
        chapter_desc: obj.chapter_desc || "",
        chapter_path: obj.chapter_path || "",
        fk_course_id: obj.fk_course_id || "",

      };
      setUploadedFiles(obj.chapter_path);
      setSelectedChapter(filteredChapterType);
      setFormCourseChapter(initialValues);
    } else {
      setChapterId("");
      setSelectedChapter([]);
      setFormCourseChapter(initialValues);
    }
    if (modalOpenFlage === false) {
      setmodalOpenFlage(true);
    }
  };

  const onDeleteOpen = (chapter_id, chapter_is_active) => {
    setConfirmationModal({ chapter_id, chapter_is_active, status: true });
  };

  const onDeleteOpenSection = (chapter_id, chapter_is_deleted) => {
    setRemoveConfirmationModal({ chapter_id, chapter_is_deleted, status: true });
  };

  const { setFieldValue, values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: formCourseChapter,
      validationSchema: chapterMasterSchema,
      onSubmit: async (values, action) => {

        let body = {
          chapter_id: chapter_id,
          chapter_title: values.chapter_title || "",
          chapter_type: selectedChapter?.value || "",
          duration: values.duration || "",
          chapter_time: values.chapter_time || "",
          chapter_desc: values.chapter_desc || "",
          chapter_path: values.chapter_path || uploadedFiles,
          fk_course_id: values.fk_course_id || "",
        };

        if (body.chapter_title != undefined || body.chapter_title != null || body.chapter_title != "") {
          setCourseChapterList([]);
          setIsLoading(true);
          setTimeout(() => {
            setDisableSubmitButton(false)
          }, 1000)
          setDisableSubmitButton(true)
          let { data, status, message } = await chapterServices.SaveChapter(body)
          if (status === 200) {
            if (data.status) {
              action.resetForm();
              toast.success(data.message);
              getAllChapters(3);
              setIsLoading(false)
              setmodalOpenFlage(false);
              setDisableSubmitButton(false)
            } else {
              toast.success(data.message);
              setErrorMessage(data.message);
              setTimeout(() => {
                setErrorMessage('')
              }, 1000)
              setIsLoading(false);
              setDisableSubmitButton(false);
            }
          } else {
            setDisableSubmitButton(false);
          }
        } else {
          setDisableSubmitButton(false)

          action.resetForm();
          if (modalOpenFlage === true) {
            getAllChapters(3);
            setmodalOpenFlage(false);
          }
          setDisableSubmitButton(false)
        }
      },
    });

  const onDeleteCourse = async (chapter_id) => {
    let { data, status } = await chapterServices.deleteChapter({ chapter_id })
    if (status === 200) {
      if (data.status) {

        toast.success(data.message);
        getAllChapters(3);
      }
    } else {

      toast.error(data.message);
    }
    setRemoveConfirmationModal({ chapter_id: null, status: false });
  };

  const handleExport = (excel_type) => {
    if (!courseChapterList || courseChapterList.length === 0) {
      toast.error("Chapter list is empty!");
      return;
    }
    if (courseChapterList?.length > 0) {
      let newSheetdata = [];
      courseChapterList.map((obj, index) => {
        const newData = {
          SN: parseInt(index) + 1,
          chapter_id: obj?.chapter_id || '-',
          fk_course_id: obj?.fk_course_id || '-',
          course_title: capitalizeFirstLetter(obj?.m_course_relation?.course_title) || '-',
          chapter_type: obj?.chapter_type || '-',
          chapter_title: capitalizeFirstLetter(obj?.chapter_title) || '-',
          chapter_desc: capitalizeFirstLetter(obj?.chapter_desc) || '-',
          chapter_path: obj?.chapter_path || '-',
          duration: obj?.duration || '-',
          chapter_time: obj?.chapter_time || '-',
          chapter_created_at: moment(obj?.chapter_created_at).format("DD-MM-YYYY") || '-',
          Status: obj?.chapter_is_active == true ? 'Active' : 'In-Active' || '-',
          user_username: obj?.chapter_cu_relation?.user_username || '-',
        }
        newSheetdata.push(newData);
      });
      let currentDate = new Date();
      let fileName = 'Course_Excel_Report_' + moment(currentDate).format("DD-MM-YYYY") + '.' + excel_type;
      const headings = [['SN', 'Chapter ID', 'Course ID', 'Course Name', 'Chapter Type', 'Chapter Name', 'Chapter Description', 'Chapter URL', 'Chapter Duration', 'Chapter Time', 'Created Date', 'Status', 'Created By']];
      const wb = utils.book_new();
      const ws = utils.json_to_sheet([]);
      utils.sheet_add_aoa(ws, headings);
      utils.sheet_add_json(ws, newSheetdata, { origin: 'A2', skipHeader: true });
      utils.book_append_sheet(wb, ws, 'Report');
      writeFile(wb, fileName);
    } else {
      toast.error("Data Not Found!");
    }
  }

  return isLoading ? (
    <FallingLinesLoader />
  ) : (
    <div className="px-4 sm:px-4 lg:px-4">
      <ConfirmationModal
        title={
          "Are you sure you want to " +
          (confirmationModal.chapter_is_active ? "Disable " : "Enable") +
          " this Course?"
        }
        confirmationButtonText="Yes"
        open={confirmationModal.status}
        onDelete={() =>
          onStatusUpdate(
            confirmationModal.chapter_id,
            confirmationModal.chapter_is_active
          )
        }
        setOpen={setConfirmationModal}
      />

      <DeleteConfirmationModal
        title={
          "Are you sure you want to " +
          (removeConfirmationModal.chapter_is_deleted
            ? " Undo"
            : "Delete") +
          " this Course?"
        }
        confirmationButtonText="Yes"
        open={removeConfirmationModal.status}
        onDelete={() => {
          console.log("confirmationModal = ", removeConfirmationModal);
          onDeleteCourse(removeConfirmationModal.chapter_id);
        }}
        setOpen={setRemoveConfirmationModal}
      />

      <Breadcrumb pages={pages} />
      <div className="">
        <h1 className="text-xl font-semibold text-white">Chapter List</h1>
      </div>
      <div className="mt-4 flex">
        {/* {allowedActions.filter((data) => data.permission_id == 28)?.length >
          0 ? ( */}
        <Link
          onClick={() => handleDrawer("add", "", {})}
          type="button"
          className="inline-flex items-center justify-center rounded-md bbt bg-[#27ff10] px-4 py-2 text-sm font-medium text-black shadow-sm hffff  ml-auto"
        >
          <PlusCircleIcon className="-ml-1.5 h-5 w-5 mr-1" aria-hidden="true" />
          Add Chapter
        </Link>
        {/* ) : null} */}
        {/* {allowedActions.filter((data) => data.permission_id == 47)?.length > 0 && courseChapterList?.length > 0 ? ( */}
        <Link
          to={"#"}
          onClick={() => handleExport('xlsx')}
          type="button"
          className="inline-flex items-center justify-center rounded-md bbt bg-[#27ff10] px-4 py-2 text-sm font-medium text-black shadow-sm hffff  ml-2"
        >
          <ArrowDownCircleIcon className="-ml-1.5 h-5 w-5 mr-1" aria-hidden="true" />
          Download Excel
        </Link>
        {/* ) : null} */}
        {/* {allowedActions.filter((data) => data.permission_id == 48)?.length > 0 ? ( */}
        <> <button
          className="inline-flex items-center justify-center rounded-md bbt bg-[#27ff10] px-4 py-2 text-sm font-medium text-black shadow-sm hffff  ml-2"
          disabled={importLoader}
          onClick={handleCourseButtonClick}
          aria-live="polite" // Optional for screen readers
        >
          {importLoader ? (
            <>
              <FaSpinner color="gold" className="spinner animate-spin" />
              <span className="ml-2">Uploading Please Wait ...</span>
            </>
          ) : (
            "Upload Chapter"
          )}
        </button>
          <input
            accept=".xlsx, .xls"
            onChange={handleCourseImport}
            type="file"
            name="user_excel"
            autoComplete="off"
            ref={fileInputRef}
            className="block w-full bg-white max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
            style={{ display: 'none' }} // Hide the file input
          /></>
        {/* ) : null} */}
      </div>

      {isLoading ? (
        <FallingLinesLoader />
      ) : (
        <Table
          columns={chapter_columns({
            onDeleteOpen,
            onDeleteOpenSection,
            handleDrawer,
            allowedActions,
          })}
          data={courseChapterList}
          is_toggle={false}
        />
      )}

      <div>
        <Transition.Root show={modalOpenFlage} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={setmodalOpenFlage}
          >
            <div className="fixed inset-0" />
            <div className="fixed inset-0 overflow-hidden">
              <div className="absolute inset-0 overflow-hidden">
                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                  <Transition.Child
                    as={Fragment}
                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                  >
                    <Dialog.Panel className="pointer-events-auto w-screen max-w-xs">
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          handleSubmit(e);
                        }}
                        className="flex h-full flex-col divide-y divide-gray-700 bg-gray-800 shadow-lg"
                      >
                        <div className="h-0 flex-1 overflow-y-auto">
                          <div className="bg-gradient-to-b from-[#2e2e34] from-10% via-gray-900 via-9% to-gray-900 to-80% py-6 px-4 sm:px-6">
                            <div className="flex items-center justify-between">
                              <Dialog.Title className="text-lg font-medium text-white">
                                {chapter_id ? "Update" : "Add"} Course
                              </Dialog.Title>
                              <div className="ml-3 flex h-7 items-center">
                                <button
                                  type="button"
                                  className="rounded-md text-cyan-200 hover:text-white"
                                  onClick={() => setmodalOpenFlage(false)}
                                >
                                  <span className="sr-only">Close panel</span>
                                  <XMarkIcon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                  />
                                </button>
                              </div>
                            </div>
                          </div>

                          <div className="flex flex-1 flex-col justify-between">
                            <div className="p-4 sm:p-6">
                              <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="chapter_title"
                                    className="block text-sm font-medium text-gray-300 mb-1"
                                  >
                                    Course Name<span className="text-red-600">*</span>

                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <select
                                    disabled
                                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:max-w-xs sm:text-sm"
                                    name="fk_course_id"
                                    id="fk_course_id"
                                    value={values?.fk_course_id || currentCourseID}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  >
                                    <option value={null}> Select Course </option>
                                    {courseList?.map((s, i) => (
                                      <option key={s.course_id} value={s.course_id}>
                                        {s.course_title}
                                      </option>
                                    ))}
                                  </select>
                                  {errors.fk_course_id && touched.fk_course_id ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.fk_course_id.replace("chapter_title", "Course Name")}
                                    </p>
                                  ) : null}
                                </div>
                              </div>

                              <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="chapter_title"
                                    className="block text-sm font-medium text-gray-300 mb-1"
                                  >
                                    Chapter Name<span className="text-red-600">*</span>

                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <input
                                    value={values.chapter_title}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="text"
                                    placeholder="Name"
                                    name="chapter_title"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-[#BE3144] sm:text-sm"
                                  />
                                  {errors.chapter_title &&
                                    touched.chapter_title ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.chapter_title}
                                    </p>
                                  ) : null}
                                </div>
                              </div>

                              <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="course_desc"
                                    className="block text-sm font-medium text-gray-300 mb-1"
                                  >
                                    Type

                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <Select
                                    name="chapter_type"
                                    placeholder={
                                      <span>
                                        Select Type
                                        <span className="text-red-600">
                                          *
                                        </span>
                                      </span>
                                    }
                                    onChange={(e) => {
                                      values.chapter_type = e.value;
                                      handleChapterType(e);
                                    }}
                                    value={selectedChapter}
                                    components={animatedComponents}
                                    options={chaptersTypeList}
                                    classNamePrefix="select"
                                    className="basic-multi-select block w-full rounded-md border-[1px]  shadow-sm  focus:border-red-500 sm:text-sm"
                                  />

                                  {errors.chapter_type && touched.chapter_type ? (
                                    <p className="text-red-600 text-sm">{errors.chapter_type}</p>
                                  ) : null}
                                </div>
                              </div>

                              {["AUDIO", "VIDEO"].includes(values.chapter_type) && (<div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="course_desc"
                                    className="block text-sm font-medium text-gray-300 mb-1"
                                  >
                                    Chpater URL

                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <input
                                    value={values.chapter_path}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="text"
                                    placeholder="Chpater URL"
                                    name="chapter_path"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-[#BE3144] sm:text-sm"
                                  />
                                  {errors.chapter_path &&
                                    touched.chapter_path ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.chapter_path}
                                    </p>
                                  ) : null}
                                </div>
                              </div>)}

                              {values.chapter_type === "FILE" && (
                                <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                  <FileUploadWithPreview
                                    onFileUpload={handleFileUpload}
                                    bannerName="Product Upload"
                                    maxFileSize={500}
                                    FolderName={'chapter'}
                                    multiple={false}
                                    doc_id={chapter_id}
                                  />
                                  {values.chapter_path}
                                  {errors.chapter_path && touched.chapter_path ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.chapter_path.replace("chapter_path", "Chapter File")}
                                    </p>
                                  ) : null}
                                </div>)}

                              {values.chapter_type === "TEXT" && (<div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="course_desc"
                                    className="block text-sm font-medium text-gray-300 mb-1"
                                  >
                                    Content

                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <textarea
                                    value={values.chapter_desc}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Content"
                                    name="chapter_desc"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-[#BE3144] sm:text-sm"
                                    rows="2"
                                  />
                                  {errors.chapter_desc &&
                                    touched.chapter_desc ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.chapter_desc}
                                    </p>
                                  ) : null}
                                </div>
                              </div>)}

                              <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="course_desc"
                                    className="block text-sm font-medium text-gray-300 mb-1"
                                  >
                                    Duration

                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <input
                                    value={values.duration}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="text"
                                    placeholder="Duration"
                                    name="duration"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-[#BE3144] sm:text-sm"
                                  />
                                  {errors.duration &&
                                    touched.duration ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.duration}
                                    </p>
                                  ) : null}
                                </div>
                              </div>

                              <div className="mt-1 pt-2 pb-2 sm:col-span-2 sm:mt-0">
                                <div className="basis-1/2 text-red-500 text-sm">
                                  {errorMessage !== "" && "Error: " + errorMessage}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-shrink-0 justify-end px-4 py-4">
                          <button
                            type="button"
                            className="rounded-md border border-gray-600 bg-gray-700 py-2 px-4 text-sm font-medium text-gray-300 shadow-sm hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                            onClick={() => {
                              setmodalOpenFlage(false);
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            disabled={disableSubmitButton}
                            type="submit"
                            className={`ml-4 inline-flex items-center justify-center rounded-md bbt bg-[#27ff10] px-4 py-2 text-sm font-medium text-black shadow-sm hffff  ml-2 ${disableSubmitButton
                              ? "bg-gray-700 hover:bg-gray-700"
                              : ""
                              }`}
                          >
                            {chapter_id ? (
                              disableSubmitButton ? (
                                <FaSpinner color="gold" />
                              ) : (
                                "Update"
                              )
                            ) : disableSubmitButton ? (
                              <FaSpinner color="gold" />
                            ) : (
                              "Add"
                            )}
                          </button>
                        </div>
                      </form>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </div>
          </Dialog>
        </Transition.Root>


      </div>
    </div>
  );
}
export default CourseChapterList;